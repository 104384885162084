/* global Modernizr */

(function() {
	if (!Date.now) {
		Date.now = function now() {
			return new Date().getTime();
		};
	}

	if(!String.prototype.endsWith) {
		String.prototype.endsWith = function(suffix) {
			return this.indexOf(suffix, this.length - suffix.length) !== -1;
		};
	}

	/*
	* The current release version (2.8.3) of Modernizr has a buggy flexbox feature detection.
	* That's why we incl. a custom build of Modernizr WITHOUT the flexbox feature detection.
	*
	* We instead use the latest Modernizr flexbox feature detection found at:
	* https://github.com/Modernizr/Modernizr/blob/master/feature-detects/css/flexbox.js
	* as a standalone test here.
	**/
	Modernizr.addTest('flexbox', Modernizr.testAllProps('flexBasis', '1px', true));

	/* flex-wrap not currently supported on Android native browser */
	Modernizr.addTest('flexwrap', Modernizr.testAllProps('flexWrap'));

	Modernizr.addTest('boxflex', Modernizr.testAllProps('boxFlex'));

	/* This tests for ie11. We need this to rescue some flexboxes. */
	if (!(window.ActiveXObject) && 'ActiveXObject' in window) {
		$('html').addClass('flexbox-noauto');
	}
}());
